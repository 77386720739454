import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Loader from "./components/home/Loader";
import "./assets/css/custom.css";
import "./assets/css/media-query.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import About from "./components/About.jsx";
import Portfolio from "./components/Portfolio.jsx";
import News from "./components/News.jsx";
import Contact from "./components/Contact.jsx";
import Cursor from "./components/common/Cursor.jsx";

function App ()
{
   const [isDarkMode, setIsDarkMode] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      // Check localStorage for the saved mode
      const storedMode = localStorage.getItem("mode");
      if (storedMode === "dark") {
        setIsDarkMode(true);
        document.body.classList.add("dark-mode");
      }
    }, []);

    useEffect(() => {
      // Handle scroll event to toggle visibility of the button
      const handleScroll = () => {
        if (window.pageYOffset > 100) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
      // Update body class and localStorage based on dark mode state
      if (isDarkMode) {
        document.body.classList.add("dark-mode");
        localStorage.setItem("mode", "dark");
      } else {
        document.body.classList.remove("dark-mode");
        localStorage.setItem("mode", "light");
      }
    }, [isDarkMode]);

    const toggleDarkMode = () => {
      setIsDarkMode((prevMode) => !prevMode);
    };

  return (
    <div className="App">
      <Loader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/news" element={<News />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Cursor />
      {/* Dark Light Button  */}
      <div className="dark_toggle">
        <div className="ul-header">
          <a
            className={`mode ${isVisible ? "" : "hidden"}`}
            onClick={toggleDarkMode}
            title="Toggle Mode"
          >
            <div className="icon-container">
              <i
                className={`fa-solid fa-sun fa-spin icon ${
                  isDarkMode ? "hidden" : ""
                }`}
                id="sunIcon"
              ></i>
              <i
                className={`fa-solid fa-moon icon ${
                  isDarkMode ? "" : "hidden"
                }`}
                id="moonIcon"
              ></i>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
